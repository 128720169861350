// import React from 'react';
// import '../style/Product.css';

// const products = [
//   {
//     id: 1,
//     name: 'White Tshirt',
//     image: 'whitetee.png',
//     description: 'White Tshirt'
//   },
//   {
//     id: 2,
//     name: 'Black Tshirt',
//     image: 'tshirt11.png',
//     description: 'Black Tshirt'
//   },
//   {
//     id: 3,
//     name: 'Acidwash Tee',
//     image: 'https://via.placeholder.com/150',
//     description: 'Acidwash Tee'
//   },

//   {
//     id: 4,
//     name: 'Hoodie',
//     image: 'https://via.placeholder.com/150',
//     description: 'Hoodie'
//   },

//    {
//     id: 5,
//     name: 'Denim Tee',
//     image: 'DenimTee.png',
//     description: 'Denim Tee'
//   },

// ];

// const Product = () => {
//   return (
//     <div className="product-list">
//       {products.map((product) => (
//         <div key={product.id} className="product-card">
//           <img src={product.image} alt={product.name} className="product-image" />
//           <h2 className="product-name">{product.name}</h2>
//           <p className="product-description">{product.description}</p>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default Product;


// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import '../style/Product.css';
// import Header from '../components/Header';

// const products = [
//   {
//     id: 1,
//     name: 'White Tshirt',
//     image: 'whitetee.png',
//     backimage: 'whiteback.png',
//     description: 'White Tshirt',
//     mockup: 'whitetee.png',
//     backmockup:'whiteback.png',
//     texture: 'whitetexture.png'
//   },
//   {
//     id: 2,
//     name: 'Black Tshirt',
//     image: 'tshirt11.png',
//     backimage: 'blackback.png',
//     description: 'Black Tshirt',
//     mockup: 'tshirt11.png',
//     backmockup:'blackback.png',
//     texture: 'texture.png'
//   },
//   {
//     id: 3,
//     name: 'Acidwash Tee',
//     image: 'https://via.placeholder.com/150',
//     description: 'Acidwash Tee',
//     mockup: 'acidwashtee.png',
//     texture: 'acidwashtexture.png'
//   },
//   {
//     id: 4,
//     name: 'Hoodie',
//     image: 'https://via.placeholder.com/150',
//     description: 'Hoodie',
//     mockup: 'hoodie.png',
//     texture: 'hoodietexture.png'
//   },
//   {
//     id: 5,
//     name: 'Denim Tee',
//     image: 'DenimTee.png',
//     description: 'Denim Tee',
//     mockup: 'denimtee.png',
//     texture: 'denimtexture.png'
//   },
// ];

// const Product = () => {
//   const navigate = useNavigate(); // Initialize useHistory hook

//   const handleProductClick = (product) => {
//     navigate('/designupload', { state: { product } }); // Navigate to design upload page with selected product
//   };

//   return (
//     <div>
  
//       <div className="product-list">
//         {products.map((product) => (
//           <div key={product.id} className="product-card" onClick={() => handleProductClick(product)}>
//             <img src={product.image} alt={product.name} className="product-image" />
//             <h2 className="product-name">{product.name}</h2>
//             <p className="product-description">{product.description}</p>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Product;


import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/Product.css';
import Header from '../components/Header';

const products = [
  {
    id: 1,
    name: 'White Oversized T-shirt',
    title:'whot',
    images: ['5.png', '6.png'],
    description: 'White Oversized T-shirt',
    mockups: ['5.png', '6.png'],
    texture:  '21.png'
  },

  {
    id: 2,
    name: 'Black Oversized T-shirt',
    title:'blot',
    images: ['1.png', '3.png'],
    description: 'Black Oversized T-shirt',
    mockups: ['1.png', '3.png'],
    texture: '21.png'
  },

  // {
  //   id: 3,
  //   name: 'Acidwash Tee',
  //   images: ['https://via.placeholder.com/150'],
  //   description: 'Acidwash Tee',
  //   mockups: ['acidwashtee.png'],
  //   texture: 'acidwashtexture.png'
  // },
  // {
  //   id: 4,
  //   name: 'Hoodie',
  //   images: ['https://via.placeholder.com/150'],
  //   description: 'Hoodie',
  //   mockups: ['hoodie.png'],
  //   texture: 'hoodietexture.png'
  // },
  // {
  //   id: 5,
  //   name: 'Denim Tee',
  //   images: ['DenimTee.png'],
  //   description: 'Denim Tee',
  //   mockups: ['denimtee.png'],
  //   texture: 'denimtexture.png'
  // },
];

const Product = () => {
  const navigate = useNavigate();

  const handleProductClick = (product) => {
    navigate('/designupload', { state: { product } });
  };

  return (
    <>
    <Header/>
    <div>
      <div className="product-list">
        {products.map((product) => (
          <div key={product.id} className="product-card" onClick={() => handleProductClick(product)}>
            <img src={product.images[0]} alt={product.name} className="product-image" />
            {/* <h2 className="product-name">{product.name}</h2> */}
            <p className="product-description">{product.description}</p>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default Product;