import {BrowserRouter, Routes, Route,} from 'react-router-dom'
import Login from '../src/pages/Login';
import Signup from '../src/pages/Signup';
import Orders from '../src/Printer/Orders';
import Dashboard from '../src/pages/Dashboard';
import DesignUpload from '../src/pages/DesignUpload';
import DesignsPage from '../src/pages/Design';
import Profile from './pages/Profile';
import AdminPanel from '../src/Admin/Admin';
import LandingPage from '../src/pages/LandingPage';
import AdminDesignsPage from '../src/Admin/AdminDesign';
import BestsellerProduct from './pages/Bestseller';
import Product from './pages/Products';
import ForgetPassword from './pages/ForgetPassword';
import EditProfile from './pages/EditProfile';
import Inventory from './Admin/Inventory';
import AdminLogin from './Admin/AdminLogin';
import AdminDashboard from './Admin/dashboard';
import AdminBtt from './Admin/AdminBtt';
import Collab from './pages/Collab';
import BTT from './pages/BTT';
import ProtectedRoute from './utils/ProtectedRouts';
import PrinterLogin from './Printer/PrinterLogin';
import ComingSoon from './pages/ComingSoon';
// import { AuthProvider } from './AuthContext';
// import firebase from './firebaseConfig'; // Import your Firebase config

function App() {

  return (
    <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/" element={<Login />} />
          <Route path="/signup" element={<Signup />} /> 
          <Route path="/login/forgetPassword" element={<ForgetPassword/>} />
          {/* <Route path="/admin-login" element={<AdminLogin/>} />
          <Route path="/Printer-login" element={<PrinterLogin/>} /> */}

         {/* <Route element= {<ProtectedRoute/>}> */}
             <Route path="/dashboard" element={<Dashboard/>} />
             <Route path="/designupload" element={<DesignUpload/>} />
             <Route path="/designs" element={<DesignsPage/>} />
             <Route path="/bestseller" element={<BestsellerProduct/>} />
             <Route path="/editprofile" element={<EditProfile/>} />
             {/* <Route path="/:artistName" element={<Profile />} /> */}
             <Route path="/products" element={<Product/>} />
             
           
             <Route path="/collab" element={<Collab/>} />
             <Route path="/collab/behind-the-tee" element={<BTT/>} />
             <Route path="/comingsoon" element={<ComingSoon/>} />      
         {/* </Route> */}

         {/* <Route path="/admin" element={<ProtectedRoute role="admin" />}> */}
          {/* <Route path="/admin/artist" element={<AdminPanel/>} />
          <Route path="/admin/design" element={<AdminDesignsPage/>} />
          <Route path="/admin/inventory" element={<Inventory/>} />
          <Route path="/admin/dashboard" element={<AdminDashboard/>} />
          <Route path="/admin/BTT" element={<AdminBtt/>} /> */}
        {/* </Route> */}


        {/* <Route path="/printer" element={<ProtectedRoute role="printer" />}> */}
        {/* <Route path="/printer/orders" element={<Orders/>} /> */}
        {/* </Route> */}

         
          
           {/* <Route path="/" element={<LandingPage/>} />  */}
          
        </Routes>
      </BrowserRouter>

      
  );
}

export default App;
