import React, { useState, useEffect, useRef } from 'react';
import { fireDB, fireStorage, auth } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { setDoc, doc, getDoc, collection, where, getDocs, query } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import '../style/designUpload.css';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fabric } from 'fabric';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrash, faRotateRight, faTimes, faEye, faPencil } from '@fortawesome/free-solid-svg-icons';

const DesignUpload = () => {
  const location = useLocation();
  const { product } = location.state || {};
  const { designId } = location.state || {};
  const frontCanvasRef = useRef(null);
  const backCanvasRef = useRef(null);
  const [frontCanvas, setFrontCanvas] = useState(null);
  const [backCanvas, setBackCanvas] = useState(null);
  const [designImages, setDesignImages] = useState({ frontDesignImage: null, backDesignImage: null });
  const [view, setView] = useState(0); // 0 for front, 1 for back
  const [designName, setDesignName] = useState('');
  const [description, setDescription] = useState('');
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [userStatus, setUserStatus] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isPreviewActive, setIsPreviewActive] = useState(false);
  const [designFileNames, setDesignFileNames] = useState({
    frontDesignImage: '',
    backDesignImage: ''
  });
 const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDocRef = doc(fireDB, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserName(userData.name);
            setUserStatus(userData.status);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      } else {
        setUser(null);
        setUserName('');
        setUserStatus(null);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const initCanvas = (ref) => {
      return new fabric.Canvas(ref.current, {
        width: 440,
        height: 540,
        backgroundColor: "#fff",
        // allowTouchScrolling: true ,
      });
    };

    const frontFabricCanvas = initCanvas(frontCanvasRef);
    const backFabricCanvas = initCanvas(backCanvasRef);

    setFrontCanvas(frontFabricCanvas);
    setBackCanvas(backFabricCanvas);

    // (function () {
    //   const addListener = fabric.util.addListener;
    //   const removeListener = fabric.util.removeListener;
    //   const addEventOptions = { passive: false };

    //   fabric.util.object.extend(fabric.Canvas.prototype, {
    //     _onTouchStart: function (e) {
    //       if (this.mainTouchId === null) {
    //         this.mainTouchId = this.getPointerId(e);
    //       }
    //       this.__onMouseDown(e);
    //       this._resetTransformEventData();
    //       const canvasElement = this.upperCanvasEl;
    //       const eventTypePrefix = this._getEventPrefix();

    //       addListener(fabric.document, 'touchend', this._onTouchEnd, addEventOptions);
    //       addListener(fabric.document, 'touchmove', this._onMouseMove, addEventOptions);
    //       removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
    //     }
    //   });
    // })();

    
    // (function () {
    //   const addListener = fabric.util.addListener;
    //   const removeListener = fabric.util.removeListener;
    //   const addEventOptions = { passive: false };
  
    //   fabric.util.object.extend(fabric.Canvas.prototype, {
    //     _onTouchStart: function (e) {
    //       (!this.allowTouchScrolling || this.getActiveObject()) && e.preventDefault && e.preventDefault();
  
    //       if (this.mainTouchId === null) {
    //         this.mainTouchId = this.getPointerId(e);
    //       }
    //       this.__onMouseDown(e);
    //       this._resetTransformEventData();
    //       const canvasElement = this.upperCanvasEl;
    //       const eventTypePrefix = this._getEventPrefix();
    //       addListener(fabric.document, 'touchend', this._onTouchEnd, addEventOptions);
    //       addListener(fabric.document, 'touchmove', this._onMouseMove, addEventOptions);
    //       // Unbind mousedown to prevent double triggers from touch devices
    //       removeListener(canvasElement, eventTypePrefix + 'down', this._onMouseDown);
    //     },
    //   });
    // })();


    return () => {
      frontFabricCanvas.dispose();
      backFabricCanvas.dispose();
    };
  }, []);

  // useEffect(() => {
  //   // Apply touch-action CSS
  //   document.querySelectorAll('canvas').forEach((canvas) => {
  //     canvas.style.touchAction = 'manipulation';
  //   });
  // }, []);

  useEffect(() => {
    const loadMockupAndTexture = (canvas, viewIndex) => {
      if (product) {
        fabric.Image.fromURL(product.mockups[viewIndex], (img) => {
          canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
          });

          const clippingRects =
            viewIndex === 0
              ? [
                  new fabric.Rect({
                    // left: 115,
                    // top: 130,
                    // width: 190,
                    // height: 250,
                    left: 115,
                    top: 130,
                    width: 210,
                    height: 300,
                    selectable: false,
                    evented: false,
                    stroke: "red",
                    strokeWidth: 1,
                    strokeDashArray: [4, 4],
                    fill: "rgba(0,0,0,0)",
                    id: "clippingRect1",
                    absolutePositioned: true,
                 
                  }),
                  new fabric.Rect({
                    left: 200,
                    top: 325,
                    width: 100,
                    height: 50,
                    selectable: false,
                    evented: false,
                    strokeWidth: 1,
                    strokeDashArray: [4, 4],
                    fill: "rgba(0,0,0,0)",
                    id: "clippingRect2",
                    absolutePositioned: true,
              
                  }),
                ]
              : [
                  new fabric.Rect({
                   
                    // left: 115,
                    // top: 105,
                    // width: 190,
                    // height: 275,
                    left: 115,
                    top: 130,
                    width: 210,
                    height: 300,
                    selectable: false,
                    evented: false,
                    stroke: "red",
                    strokeWidth: 1,
                    strokeDashArray: [4, 4],
                    fill: "rgba(0,0,0,0)",
                    id: "clippingRectBack",
                    absolutePositioned: true,
             
                  }),
                ];

          clippingRects.forEach((rect) => canvas.add(rect));
          canvas.clipPaths = clippingRects;

          canvas.renderAll();
        });

        fabric.Image.fromURL(product.texture, (img) => {
          canvas.setOverlayImage(img, canvas.renderAll.bind(canvas), {
            scaleX: canvas.width / img.width,
            scaleY: canvas.height / img.height,
          });
        });
      }
    };

    if (frontCanvas) {
      loadMockupAndTexture(frontCanvas, 0);
    }
    if (backCanvas) {
      loadMockupAndTexture(backCanvas, 1);
    }
  }, [product, frontCanvas, backCanvas]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;

    if (!file || !file.type.startsWith("image/png")) {
      toast.error("Please upload a PNG file.");
      return;
    }

    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function () {
        const dpi = Math.round(img.width / (activeCanvas.width / 25.4));
        if (dpi < 150) {
          toast.error("Print quality bad, high resolution art required.");
          return;
        } else {
          toast.success("Print quality good.");
        }

        setDesignFileNames((prev) => ({
          ...prev,
          [view === 0 ? "frontDesignImage" : "backDesignImage"]: file.name
        }));

        setDesignImages((prev) => ({
          ...prev,
          [view === 0 ? "frontDesignImage" : "backDesignImage"]: file,
        }));
        const fabricImg = new fabric.Image(img);

        if (view === 0) {
          const scaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
          fabricImg.scale(scaleFactor);
          fabricImg.set({
            left: 115 + (210 - fabricImg.width * scaleFactor) / 2,
            top: 130 + (300 - fabricImg.height * scaleFactor) / 2,
            clipPath: new fabric.Rect({
              // left: 100,
              // top: 90,
              // width: 200,
              // height: 230,
              left: 115,
              top: 130,
              width: 210,
              height: 300,
              absolutePositioned: true,
          
            }),
          });
        } else {
          const scaleFactor = Math.min(210 / fabricImg.width, 300 / fabricImg.height);
          fabricImg.scale(scaleFactor);
          fabricImg.set({
            left: 115 + (210 - fabricImg.width * scaleFactor) / 2,
            top: 130 + (300 - fabricImg.height * scaleFactor) / 2,
            clipPath: new fabric.Rect({
              left: 115,
              top: 130,
              width: 210,
              height: 300,
              absolutePositioned: true,
              
            }),
          });
        }

        activeCanvas.add(fabricImg);
        activeCanvas.renderAll();
      };
    };

    reader.readAsDataURL(file);
    setDrawerOpen(false);
  };

  const handleDelete = () => {
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObject = activeCanvas.getActiveObject();
    if (activeObject) {
      activeCanvas.remove(activeObject);
    }
  };

  const handleRotate = () => {
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    const activeObject = activeCanvas.getActiveObject();
    if (activeObject) {
      activeObject.rotate(activeObject.angle + 90);
      activeCanvas.renderAll();
    }
  };

  // const togglePreview = () => {
  //   setIsPreviewActive(!isPreviewActive);
  
  //   const activeCanvas = view === 0 ? frontCanvas : backCanvas;
  //   activeCanvas.getObjects("rect").forEach((rect) => {
  //     rect.set({ visible: !rect.visible });
  //   });
  //   activeCanvas.renderAll();
  // };


  const generateSKU = async (designName, userName, product) => {
    const productPrefix = product && product.name === 'Black Oversized T-shirt' ? 'blot' : 'whot';
    const userNameInitials = userName.split(' ').map(word => word[0].toLowerCase()).join('');
    const designInitials = designName.split(' ').map(word => word[0].toLowerCase()).join('');
    
    // Get the number of designs previously uploaded by the user
    const designsRef = collection(fireDB, 'designs');
    const q = query(designsRef, where("userName", "==", userName));
    const querySnapshot = await getDocs(q);
    const designCount = querySnapshot.size + 1;
    const designNumber = designCount < 10 ? `0${designCount}` : designCount;

    const sizes = ['s', 'm', 'l', 'xl', 'xxl'];
    const skuList = sizes.map(size => `${productPrefix}-${size}-${userNameInitials}-${designInitials}${designNumber}-tshirt`);

    return skuList;
  };


  const togglePreview = (mode) => {
    const isPreview = mode === 'preview';
    setIsPreviewActive(isPreview);
  
    const activeCanvas = view === 0 ? frontCanvas : backCanvas;
    activeCanvas.getObjects("rect").forEach((rect) => {
      rect.set({ visible: !rect.visible });
    });
    activeCanvas.renderAll();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      alert("User is not authenticated. Please log in.");
      return;
    }

    if (!designImages.frontDesignImage && !designImages.backDesignImage) {
      alert("Please upload at least one design image (front or back).");
      return;
    }

    try {
      const dataURItoBlob = (dataURI) => {
        let byteString;
        if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
        else
          byteString = unescape(dataURI.split(',')[1]);

        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ia], { type: mimeString });
      };

      const saveCanvasToStorage = async (canvas, canvasName) => {

        canvas.getObjects("rect").forEach((rect) => rect.set({ visible: false }));
        canvas.renderAll();
        const canvasJPEG = canvas.toDataURL({ format: 'jpeg' });
        const canvasRef = ref(fireStorage, `mockups/${designName}-${canvasName}.jpeg`);
        await uploadBytes(canvasRef, dataURItoBlob(canvasJPEG));
        return await getDownloadURL(canvasRef);
      };

      let frontDesignURL = '';
      if (designImages.frontDesignImage) {
        const frontDesignRef = ref(fireStorage, `designs/${designName}-front-design.png`);
        await uploadBytes(frontDesignRef, designImages.frontDesignImage);
        frontDesignURL = await getDownloadURL(frontDesignRef);
      }

      let backDesignURL = '';
      if (designImages.backDesignImage) {
        const backDesignRef = ref(fireStorage, `designs/${designName}-back-design.png`);
        await uploadBytes(backDesignRef, designImages.backDesignImage);
        backDesignURL = await getDownloadURL(backDesignRef);
      }

      const frontMockupURL = frontCanvas ? await saveCanvasToStorage(frontCanvas, 'front') : '';
      const backMockupURL = backCanvas ? await saveCanvasToStorage(backCanvas, 'back') : '';

      const designDocRef = doc(fireDB, 'designs', `${Date.now()}`);

        // Generate SKU for the design (if needed)
        const skuList = await generateSKU(designName, userName, product);

      await setDoc(designDocRef, {
        designName,
        description,
        product: product ? product.name : '',
        frontDesignURL,
        backDesignURL,
        frontMockupURL,
        backMockupURL,
        createdAt: new Date(),
        userName,
        skus: skuList, 
      });

      setDesignName('');
      setDescription('');
      toast.success('Design uploaded successfully!');
      navigate("/designs");
    } catch (error) {
      console.error('Error uploading design:', error.message);
      toast.error('Failed to upload design. Please try again later.');
    }
  };

  const setOpenDrawer = (isOpen) => {
    setDrawerOpen(isOpen);
  };

  return (
  <div>
    <Header/>
     <div className="design-upload-container">

     <div className={`drawer ${drawerOpen ? 'open' : ''}`}>
            <div className="drawer-header">
              <button className="close-drawer" onClick={() => setOpenDrawer(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

        <div className="form-wrapper">
          <h2>Design Upload</h2>
          <ToastContainer />
          {user ? (
            userStatus === 'approved' ? (
              <form onSubmit={handleSubmit}>

            <div className="form-group">
                  {/* <label htmlFor="designImage">Upload Design Image:</label>
                  <input
                    type="file"
                    id="designImage"
                    accept=".png"
                    onChange={(e) => handleFileUpload(e)}
                  /> */}
      <label >Upload Design Image:</label>
      <input
        type="file"
        id="designImage"
        accept=".png"
        onChange={(e) => handleFileUpload(e)}
        style={{ display: 'none' }} // Hide the default file input
      />
      <label htmlFor="designImage" className="custom-file-upload">
        + Add Image
      </label>

      {/* Display the file name below the Add Image button */}
        {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"] && (
        <p>Uploaded: {designFileNames[view === 0 ? "frontDesignImage" : "backDesignImage"]}</p>
          )}

                </div>
                <div className="form-group">
                  <label htmlFor="designName">Design Name:</label>
                  <input
                    type="text"
                    id="designName"
                    value={designName}
                    onChange={(e) => setDesignName(e.target.value)}
                    placeholder="Design Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="description">Description:</label>
                  <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                    required
                  />
                </div>
               
                <button type="submit" className='desktop-submit'>Submit</button>
              </form>
            ) : (
              <p>Your application is under process. Please wait for approval.</p>
            )
          ) : (
            <p>Please log in to upload your design.</p>
          )}
        </div>
   </div>


        <div className="left-container">

        <div className="view-and-customize">
         <select onChange={(e) => setView(Number(e.target.value))}  className="view-select">
            <option value="0">Front</option>
            <option value="1">Back</option>
          </select>
      
          <button onClick={() => setOpenDrawer(true)} className="customize-button">
              Customize
          </button>

          <button onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button onClick={handleRotate}>
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
        </div>


          {/* <div className="preview-controls">
          
            <button onClick={handleDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
            <button onClick={handleRotate}>
              <FontAwesomeIcon icon={faRotateRight} />
            </button>
          </div> */}

          <div className="mockup">
          <div style={{ display: view === 0 ? 'block' : 'none' }}>
            <canvas ref={frontCanvasRef} />
          </div>
          <div style={{ display: view === 1 ? 'block' : 'none' }}>
            <canvas ref={backCanvasRef} />
          </div>
          </div>

        

        <div className="toggle-buttons">
  <button
    onClick={() => togglePreview('preview')}
    className={`toggle-button ${isPreviewActive ? 'active' : ''}`}
  >
    <i className="fa fa-eye" aria-hidden="true"></i> Preview
  </button>
  <button
    onClick={() => togglePreview('design')}
    className={`toggle-button ${!isPreviewActive ? 'active' : ''}`}
  >
    <i className="fa fa-pencil" aria-hidden="true"></i> Design
  </button>
</div>

<button className="submit-button" onClick={handleSubmit}>
    Submit
  </button>


        </div>
      </div>

 
  </div>
);

};

export default DesignUpload;
