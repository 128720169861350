import React, { useState, useEffect } from 'react';
import { setDoc, doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { fireDB, fireStorage, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import '../style/BTT.css';

const BTT = () => {
  const [questions, setQuestions] = useState([
    { id: 1, question: 'Introduction', video: null },
    { id: 2, question: 'What is your artwork inspired by?', video: null },
    { id: 3, question: 'Your art style in one word', video: null },
    { id: 4, question: 'Favorite tool or software', video: null },
    { id: 5, question: 'Favorite design from your collection', video: null },
    { id: 6, question: 'Your thoughts on Unitee culture', video: null },
  ]);

  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          const userDocRef = doc(fireDB, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserName(userData.name);
            setUserStatus(userData.status);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      } else {
        setUser(null);
        setUserName('');
        setUserStatus(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleVideoUpload = (e, index) => {
    const file = e.target.files[0];
    const newQuestions = [...questions];
    newQuestions[index].video = file;
    setQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    if (!user) {
      alert("User is not authenticated. Please log in.");
      return;
    }

    const uploads = questions.map(async (q) => {
      if (q.video) {
        const storageRef = ref(fireStorage, `videos/${user.uid}/${q.id}/${q.video.name}`);
        await uploadBytes(storageRef, q.video);
        const videoURL = await getDownloadURL(storageRef);
        return { id: q.id, question: q.question, videoURL };
      }
      return { id: q.id, question: q.question, videoURL: null };
    });

    const results = await Promise.all(uploads);

    const videosData = results.reduce((acc, result) => {
      acc[`video${result.id}`] = result.videoURL;
      return acc;
    }, {});

    await setDoc(doc(fireDB, 'questions', user.uid), {
      userName,
      videos: videosData,
    });

    alert('Data submitted successfully!');
  };

  if (!user) {
    return <p>Please log in to access this page.</p>;
  }

  return (
    <div className="container">
      <h1>Questions</h1>
      {questions.map((q, index) => (
        <div key={q.id} className="question-container">
          <p className="question-text">{q.question}</p>
          <input
            type="file"
            accept="video/*"
            onChange={(e) => handleVideoUpload(e, index)}
            className="upload-input"
          />
        </div>
      ))}
      <button onClick={handleSubmit} className="submit-button">Submit</button>
    </div>
  );
};

export default BTT;
