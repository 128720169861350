import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style/editprofile.css';
import { auth, fireDB, fireStorage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import Header from '../components/Header';

const EditProfile = () => {
  const [userData, setUserData] = useState({
    uid:'',
    name: '',
    email: '',
    instagramId: '',
    phoneNumber: '',
    portfolioLink: '',
    profileImageUrl: '',
    bio: '',
    IFSCCode: '',
    Branch: '',
    AccountNumber: '',

  });
  
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [userStatus, setUserStatus] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDoc = await getDoc(doc(fireDB, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData(data);
            setUserStatus(data.status); // Assuming 'status' is a field in the user document
            if (data.profileImageUrl) {
              setImagePreview(data.profileImageUrl);
            }
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const currentUser = auth.currentUser;
    if (currentUser) {
      try {
        if (imageFile) {
          const imageRef = ref(fireStorage, `profileImages/${currentUser.uid}`);
          await uploadBytes(imageRef, imageFile);
          const imageUrl = await getDownloadURL(imageRef);
          userData.profileImageUrl = imageUrl;
        }
        await updateDoc(doc(fireDB, 'users', currentUser.uid), userData);
        console.log('Profile Updated');
        // navigate(`/${userData.name}`); 
      } catch (err) {
        console.error('Error updating profile:', err);
      }
    }
  };

//   return (
//     <div>
//       <Header />
//       <div className="profile-container">
//         <h2>Edit Profile</h2>
//         {loading ? (
//           <p>Loading...</p>
//         ) : (
//           <>
//             {user ? (
//               userStatus === 'approved' ? (
//                 <div className="profile-content">
//                   <form onSubmit={handleSubmit} className="profile-form">
//                     <label htmlFor="name">
//                       Artist Name:
//                       <input
//                         type="text"
//                         name="name"
//                         value={userData.name}
//                         onChange={handleChange}
//                       />
//                     </label>
//                     <label htmlFor="email">
//                       Email:
//                       <input
//                         type="text"
//                         name="email"
//                         value={userData.email}
//                         onChange={handleChange}
//                         disabled
//                       />
//                     </label>
//                     <label htmlFor="instagramId">
//                       Instagram ID:
//                       <input
//                         type="text"
//                         name="instagramId"
//                         value={userData.instagramId}
//                         onChange={handleChange}
//                       />
//                     </label>
//                     <label htmlFor="phoneNumber">
//                       Phone Number:
//                       <input
//                         type="text"
//                         name="phoneNumber"
//                         value={userData.phoneNumber}
//                         onChange={handleChange}
//                       />
//                     </label>
//                     <label htmlFor="portfolioLink">
//                       Portfolio Link:
//                       <input
//                         type="text"
//                         name="portfolioLink"
//                         value={userData.portfolioLink}
//                         onChange={handleChange}
//                       />
//                     </label>
//                     <label htmlFor="bio">
//                       Bio:
//                       <textarea
//                         name="bio"
//                         value={userData.bio}
//                         onChange={handleChange}
//                       />
//                     </label>

//                     <label htmlFor="IFSCCode">
//                       IFSC Code:
//                        <input type="text"
//                         name="IFSCCode"
//                         value={userData.IFSCCode}
//                         onChange={handleChange}
//                       />
//                     </label>

//                     <label htmlFor="Branch">
//                       Branch:
//                        <input type="text"
//                         name="Branch"
//                         value={userData.Branch}
//                         onChange={handleChange}
//                       />
//                     </label>

//                     <label htmlFor="AccountN">
//                      Account Number:
//                        <input type="text"
//                         name="AccountN"
//                         value={userData.AccountN}
//                         onChange={handleChange}
//                       />
//                     </label>


//                     <div className="button-group">
//                       <button type="submit" className="save-button">Save</button>
//                     </div>
//                   </form>
//                   <div className="profile-image-section">
//                     <label htmlFor="profileImage" className="profile-image-label">
//                       Upload Profile Image:
//                       <input
//                         type="file"
//                         accept="image/*"
//                         onChange={handleImageChange}
//                       />
//                     </label>
//                     {imagePreview && <img src={imagePreview} alt="Profile Preview" className="profile-preview" />}
//                   </div>
//                 </div>
//               ) : (
//                 <p>Your application is under process. Please wait for approval.</p>
//               )
//             ) : (
//               <p>No user found. Please login again.</p>
//             )}
//           </>
//         )}
//       </div>
//     </div>
//   );
// };

// export default EditProfile;

return (
  <div className="😊edit-profile">
    <Header />
    <div className="😊edit-profile-container">
      <h2 className="😊edit-profile-heading">Edit Profile</h2>
      {loading ? (
        <p className="😊loading-text">Loading...</p>
      ) : (
        <>
          {user ? (
            userStatus === 'approved' ? (
              <div className="😊edit-profile-content">
                <div className="😊profile-image-section">
                  <label htmlFor="😊profileImage" className="😊profile-image-label">
                    Upload Profile Image:
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      className="😊profile-image-input"
                    />
                  </label>
                  {imagePreview && (
                    <img
                      src={imagePreview}
                      alt="Profile Preview"
                      className="😊profile-image-preview"
                    />
                  )}
                </div>
                <form onSubmit={handleSubmit} className="edit-profile-form">
                  <label htmlFor="name" className="form-label">
                    Artist Name:
                    <input
                      type="text"
                      name="name"
                      value={userData.name}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="email" className="😊form-label">
                    Email:
                    <input
                      type="text"
                      name="email"
                      value={userData.email}
                      onChange={handleChange}
                      disabled
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="instagramId" className="😊form-label">
                    Instagram ID:
                    <input
                      type="text"
                      name="instagramId"
                      value={userData.instagramId}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="phoneNumber" className="😊form-label">
                    Phone Number:
                    <input
                      type="text"
                      name="phoneNumber"
                      value={userData.phoneNumber}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="portfolioLink" className="😊form-label">
                    Portfolio Link:
                    <input
                      type="text"
                      name="portfolioLink"
                      value={userData.portfolioLink}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="bio" className="😊form-label">
                    Bio:
                    <textarea
                      name="bio"
                      value={userData.bio}
                      onChange={handleChange}
                      className="😊form-textarea"
                    />
                  </label>
                  <label htmlFor="IFSCCode" className="😊form-label">
                    IFSC Code:
                    <input
                      type="text"
                      name="IFSCCode"
                      value={userData.IFSCCode}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="Branch" className="😊form-label">
                    Branch:
                    <input
                      type="text"
                      name="Branch"
                      value={userData.Branch}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <label htmlFor="AccountN" className="😊form-label">
                    Account Number:
                    <input
                      type="text"
                      name="AccountN"
                      value={userData.AccountN}
                      onChange={handleChange}
                      className="😊form-input"
                    />
                  </label>
                  <div className="😊form-submit-section">
                    <button type="submit" className="😊submit-button">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <p className="😊pending-approval-text">
                Your application is under process. Please wait for approval.
              </p>
            )
          ) : (
            <p className="😊no-user-text">No user found. Please login again.</p>
          )}
        </>
      )}
    </div>
  </div>
);
};

export default EditProfile;