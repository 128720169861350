// import React, { useState, useEffect } from 'react';
// import { auth, fireDB } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';
// import '../style/designs.css';
// import Header from '../components/Header';

// const DesignsPage = () => {
//   const [designs, setDesigns] = useState([]);
//   const [user, setUser] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       if (currentUser) {
//         setUser(currentUser);
//         const userName = await fetchUserName(currentUser.uid);
//         if (userName) {
//           fetchDesigns(userName);
//         }
//       } else {
//         setUser(null);
//         setDesigns([]);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchUserName = async (userId) => {
//     try {
//       const userDoc = doc(fireDB, 'users', userId);
//       const userSnap = await getDoc(userDoc);
//       if (userSnap.exists()) {
//         return userSnap.data().name;
//       } else {
//         console.error('No such user document!');
//         return null;
//       }
//     } catch (error) {
//       console.error('Error fetching user name:', error.message);
//       return null;
//     }
//   };

//   const fetchDesigns = async (userName) => {
//     try {
//       const designsCollection = collection(fireDB, 'designs');
//       const q = query(designsCollection, where('userName', '==', userName));
//       const designsSnapshot = await getDocs(q);
//       const designsData = designsSnapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       console.log('Fetched designs:', designsData);
//       setDesigns(designsData);
//     } catch (error) {
//       console.error('Error fetching designs:', error.message);
//     }
//   };

//   const handleAddDesignClick = () => {
//    navigate('/products'); // Navigate to the design upload page
//   };

//   return (
//     <div>
//       <Header />
//       <div className='container'>
//         <h2>Your Designs</h2>
//         <button 
//           onClick={handleAddDesignClick}
//           style={{ float: 'right', marginBottom: '10px' }}
//         >
//           Add Design
//         </button>
//         <table>
//           <thead>
//             <tr>
//               <th>Design Name</th>
//               <th>Description</th>
//               <th>Category</th>
//               <th>Product</th>
//               <th>Design Image</th>
//               <th>Created At</th>
//             </tr>
//           </thead>
//           <tbody>
//             {designs.map(design => (
//               <tr key={design.id}>
//                 <td>{design.designName}</td>
//                 <td>{design.description}</td>
//                 <td>{design.category}</td>
//                 <td>{design.product}</td>
//                 <td>
//                   <img 
//                     src={design.fileURL} 
//                     alt={design.designName} 
//                     style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
//                   />
//                 </td>
//                 <td>{design.createdAt.toDate().toLocaleString()}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default DesignsPage;


// import React, { useState, useEffect } from 'react';
// import { auth, fireDB } from '../firebase';
// import { onAuthStateChanged } from 'firebase/auth';
// import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
// import { useNavigate } from 'react-router-dom';
// import '../style/designs.css';
// import Header from '../components/Header';

// const DesignsPage = () => {
//   const [designs, setDesigns] = useState([]);
//   const [user, setUser] = useState(null);
//   const [viewMode, setViewMode] = useState('list'); // State to manage view mode
//   const navigate = useNavigate();

//   useEffect(() => {
//     const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
//       if (currentUser) {
//         setUser(currentUser);
//         const userName = await fetchUserName(currentUser.uid);
//         if (userName) {
//           fetchDesigns(userName);
//         }
//       } else {
//         setUser(null);
//         setDesigns([]);
//       }
//     });

//     return () => unsubscribe();
//   }, []);

//   const fetchUserName = async (userId) => {
//     try {
//       const userDoc = doc(fireDB, 'users', userId);
//       const userSnap = await getDoc(userDoc);
//       if (userSnap.exists()) {
//         return userSnap.data().name;
//       } else {
//         console.error('No such user document!');
//         return null;
//       }
//     } catch (error) {
//       console.error('Error fetching user name:', error.message);
//       return null;
//     }
//   };

//   const fetchDesigns = async (userName) => {
//     try {
//       const designsCollection = collection(fireDB, 'designs');
//       const q = query(designsCollection, where('userName', '==', userName));
//       const designsSnapshot = await getDocs(q);
//       const designsData = designsSnapshot.docs.map(doc => ({
//         id: doc.id,
//         ...doc.data()
//       }));
//       console.log('Fetched designs:', designsData);
//       setDesigns(designsData);
//     } catch (error) {
//       console.error('Error fetching designs:', error.message);
//     }
//   };

//   const handleAddDesignClick = () => {
//     navigate('/products'); // Navigate to the design upload page
//   };

//   const handleViewToggle = (mode) => {
//     setViewMode(mode);
//   };

//   return (
//     <div>
//       <Header />
//       <div className='container'>
//         <h2>Your Designs</h2>
//         <div className="view-toggle-buttons">
//           <button onClick={() => handleViewToggle('list')}>List View</button>
//           <button onClick={() => handleViewToggle('grid')}>Grid View</button>
//         </div>
//         <button 
//           onClick={handleAddDesignClick}
//           style={{ float: 'right', marginBottom: '10px' }}
//         >
//           Add Design
//         </button>
//         {viewMode === 'list' ? (
//           <table>
//             <thead>
//               <tr>
//                 <th>Design Name</th>
//                 <th>Front Mockup</th>
//                 <th>Back Mockup</th>
//               </tr>
//             </thead>
//             <tbody>
//               {designs.map(design => (
//                 <tr key={design.id}>
//                   <td>{design.designName}</td>
//                   <td>
//                     <img 
//                       src={design.frontMockupURL} 
//                       alt={`${design.designName} Front Mockup`} 
//                       style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
//                     />
//                   </td>
//                   <td>
//                     <img 
//                       src={design.backMockupURL} 
//                       alt={`${design.designName} Back Mockup`} 
//                       style={{ width: '100px', height: '100px', objectFit: 'cover' }} 
//                     />
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         ) : (
//           <div className="grid-view">
//             {designs.map(design => (
//               <div key={design.id} className="grid-item">
//                 <h3>{design.designName}</h3>
//                 <img 
//                   src={design.frontMockupURL} 
//                   alt={`${design.designName} Front Mockup`} 
//                   style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
//                 />
//                 <img 
//                   src={design.backMockupURL} 
//                   alt={`${design.designName} Back Mockup`} 
//                   style={{ width: '150px', height: '150px', objectFit: 'cover' }} 
//                 />
//               </div>
//             ))}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default DesignsPage;


import React, { useState, useEffect } from 'react';
import { auth, fireDB } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, query, where, doc, getDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import '../style/designs.css';
import Header from '../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList, faTh, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import ReactModal from 'react-modal';
import { toast } from 'react-toastify';
import Modal from 'react-modal';

const DesignsPage = () => {
  const [designs, setDesigns] = useState([]);
  const [user, setUser] = useState(null);
  const [view, setView] = useState('grid'); // 'list' or 'grid'
  const [showModal, setShowModal] = useState(false);
  const [designIdToDelete, setDesignIdToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userName = await fetchUserName(currentUser.uid);
        if (userName) {
          fetchDesigns(userName);
        }
      } else {
        setUser(null);
        setDesigns([]);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserName = async (userId) => {
    try {
      const userDoc = doc(fireDB, 'users', userId);
      const userSnap = await getDoc(userDoc);
      if (userSnap.exists()) {
        return userSnap.data().name;
      } else {
        console.error('No such user document!');
        return null;
      }
    } catch (error) {
      console.error('Error fetching user name:', error.message);
      return null;
    }
  };

  const fetchDesigns = async (userName) => {
    try {
      const designsCollection = collection(fireDB, 'designs');
      const q = query(designsCollection, where('userName', '==', userName));
      const designsSnapshot = await getDocs(q);
      const designsData = designsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('Fetched designs:', designsData);
      setDesigns(designsData);
    } catch (error) {
      console.error('Error fetching designs:', error.message);
    }
  };

  const handleAddDesignClick = () => {
    navigate('/products'); // Navigate to the design upload page
  };

  // const handleDeleteDesign = async (designId) => {
  //   try {
  //     await deleteDoc(doc(fireDB, 'designs', designId));
  //     setDesigns(designs.filter(design => design.id !== designId));
  //     console.log('Design deleted:', designId);
  //   } catch (error) {
  //     console.error('Error deleting design:', error.message);
  //   }
  // };

  const handleDeleteClick = (designId) => {
    setDesignIdToDelete(designId);
    setShowModal(true);
  };

  const handleDeleteDesign = async (designId) => {
    // try {
    //   await deleteDoc(doc(fireDB, 'designs', designIdToDelete));
    //   toast.success('Design deleted successfully!');
    // } catch (error) {
    //   console.error('Error deleting design:', error);
    //   toast.error('Failed to delete design.');

    try {
      await deleteDoc(doc(fireDB, 'designs', designIdToDelete));
      toast.success('Design deleted successfully!');
      // Update local state to remove deleted design
      setDesigns((prevDesigns) => prevDesigns.filter(design => design.id !== designIdToDelete));
    } catch (error) {
      console.error('Error deleting design:', error);
      toast.error('Failed to delete design.');
    } finally {
      setShowModal(false);
      setDesignIdToDelete(null);
    }
  };

  const handleEditDesign = (designId) => {
    navigate('/design-upload', { state: { designId } }); // Navigate to the edit page with the design ID
  };

  return (
    <div>
      <Header />
      <div className='container'>
        <h2>Your Designs</h2>
        <button 
          onClick={handleAddDesignClick}
          className='add-design-btn'
        >
          Add Design
        </button>
        <div className='view-toggle'>
          <button 
            className={`view-button ${view === 'list' ? 'active' : ''}`}
            onClick={() => setView('list')}
          >
            <FontAwesomeIcon icon={faThList} />
          </button>
          <button 
            className={`view-button ${view === 'grid' ? 'active' : ''}`}
            onClick={() => setView('grid')}
          >
            <FontAwesomeIcon icon={faTh} />
          </button>
        </div>
        {view === 'list' ? (
          <table className='designs-table'>
            <thead>
              <tr>
                <th>Design Name</th>
                <th>Front Mockup</th>
                <th>Back Mockup</th>
                {/* <th>Created At</th> */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {designs.map(design => (
                <tr key={design.id}>
                  <td>{design.designName}</td>
                  <td>
                    <img
                      src={design.frontMockupURL}
                      alt={`${design.designName} Front Mockup`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={design.backMockupURL}
                      alt={`${design.designName} Back Mockup`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                
                  <td>
                    {/* <button 
                      onClick={() => handleEditDesign(design.id)} 
                      className='action-button'
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </button> */}
                    <button 
                      onClick={() => handleDeleteClick(design.id)} 
                      className='action-button'
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>

                    <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Confirmation Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this design?</p>
        <div className="modal-buttons">
          <button onClick={handleDeleteDesign}>Delete</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      </Modal>
                  </td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className='designs-grid'>
            {designs.map(design => (
              <div className='design-item' key={design.id}>
                <h3>{design.designName}</h3>
                <div className='mockup-row'>
                  <img
                    src={design.frontMockupURL}
                    alt={`${design.designName} Front Mockup`}
                    className='mockup-image'
                  />
                  <img
                    src={design.backMockupURL}
                    alt={`${design.designName} Back Mockup`}
                    className='mockup-image'
                  />
                </div>
                

                <div className='actions'>
                  {/* <button 
                    onClick={() => handleEditDesign(design.id)} 
                    className='action-button'
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button> */}
                  <button 
                    onClick={() => handleDeleteClick(design.id)} 
                    className='action-button'
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>

                  <Modal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        contentLabel="Confirmation Modal"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2>Confirm Delete</h2>
        <p>Are you sure you want to delete this design?</p>
        <div className="modal-buttons">
          <button onClick={handleDeleteDesign}>Delete</button>
          <button onClick={() => setShowModal(false)}>Cancel</button>
        </div>
      </Modal>
                </div>



              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DesignsPage;
