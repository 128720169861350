import React, { useState } from 'react';
import '../style/signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth, fireDB } from '../firebase'; // Ensure firebase is properly imported
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { setDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import axios from 'axios';
import Header from '../components/Header';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [instagramId, setInstagramId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [portfolioLink, setPortfolioLink] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [instagramIdError, setInstagramIdError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [portfolioLinkError, setPortfolioLinkError] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); 

  const navigate = useNavigate(); // Initialize useNavigate

  const generateSKU = async (name) => {
    const initials = name.split(' ').map(n => n[0].toLowerCase()).join('');

    const usersCollection = collection(fireDB, 'users');
    const q = query(usersCollection, where('sku', '>=', initials), where('sku', '<', initials + 'z'));
    const querySnapshot = await getDocs(q);

    let newSku = initials;
    if (!querySnapshot.empty) {
      const skus = querySnapshot.docs.map(doc => doc.data().sku);
      let suffix = 1;
      while (skus.includes(newSku + suffix)) {
        suffix++;
      }
      newSku += suffix;
    }

    return newSku;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phoneNumber);
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset all error states
    setNameError('');
    setEmailError('');
    setPasswordError('');
    setInstagramIdError('');
    setPhoneNumberError('');
    setPortfolioLinkError('');

    let isValid = true;

    // Check if any field is empty
    if (!name) {
      setNameError('Artist name is required.');
      isValid = false;
    }
    if (!email) {
      setEmailError('Email is required.');
      isValid = false;
    } else if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      isValid = false;
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    } else if (!validatePassword(password)) {
      setPasswordError('Password must be at least 6 characters long.');
      isValid = false;
    }

    if (!instagramId) {
      setInstagramIdError('Instagram ID is required.');
      isValid = false;
    }

    if (!phoneNumber) {
      setPhoneNumberError('Phone number is required.');
      isValid = false;
    } else if (!validatePhoneNumber(phoneNumber)) {
      setPhoneNumberError('Phone number must be exactly 10 digits.');
      isValid = false;
    }

    // if (!portfolioLink) {
    //   setPortfolioLinkError('Portfolio link is required.');
    //   isValid = false;
    // } else if (!portfolioLink.startsWith('http://') && !portfolioLink.startsWith('https://')) {
    //   setPortfolioLinkError('Please enter a valid portfolio link.');
    //   isValid = false;
    // }

    if (!isValid) return; // Stop the form submission if there are validation errors

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const sku = await generateSKU(name);
      
      // Save user data to Firestore
      await setDoc(doc(fireDB, 'users', user.uid), {
        uid: user.uid, // Store UID here
        name,
        email,
        instagramId,
        phoneNumber,
        portfolioLink,
        sku
      });
  
      // // Send user data to the server if needed
      // await axios.post('https://unitee-server.onrender.com/api/users', {
      //   name,
      //   email,
      //   instagramId,
      //   phoneNumber,
      //   portfolioLink,
      //   sku
      // });

      console.log(`Account Created with SKU: ${sku}`);
      // Redirect to dashboard
      setSuccessMessage('Sign up successful!');

      // Redirect to login page after 1 seconds
      setTimeout(() => {
        navigate('/');
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Header />
      <div className='signup-container'>
        <form className="signupform" onSubmit={handleSubmit}>
          <h2>Sign Up</h2>
          {successMessage && <p className="success-message">{successMessage}</p>} {/* Display success message if any */}
          <label htmlFor="name">Artist Name:
            <input type='text' value={name} onChange={(e) => setName(e.target.value)} />
            {nameError && <p className="error-message">{nameError}</p>}
          </label>
          <label htmlFor="email">Email:
            <input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
            {emailError && <p className="error-message">{emailError}</p>}
          </label>
          <label htmlFor="instagram_id">Instagram ID:
            <input type='text' value={instagramId} onChange={(e) => setInstagramId(e.target.value)} />
            {instagramIdError && <p className="error-message">{instagramIdError}</p>}
          </label>
          <label htmlFor="phone_no">Phone Number:
            <input type='text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
            {phoneNumberError && <p className="error-message">{phoneNumberError}</p>}
          </label>
          <label htmlFor="portfolio">Portfolio Link:
            <input type='text' value={portfolioLink} onChange={(e) => setPortfolioLink(e.target.value)} />
            {/* {portfolioLinkError && <p className="error-message">{portfolioLinkError}</p>} */}
          </label>
          <label htmlFor="password">Password:
            <input type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            {passwordError && <p className="error-message">{passwordError}</p>}
          </label>
          <button type="submit">Sign Up</button>
          <p>Already Registered? <Link to='/'>Login</Link></p>
        </form>
      </div>
    </div>
  );
};

export default Signup;