import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/BestsellerProduct.css';
import API_URL from '../config';

const BestsellerProduct = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchBestsellerProducts = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/bestsellers`);
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching bestseller products:', error);
      }
    };

    fetchBestsellerProducts();
  }, []);

  if (products.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="products">
      {products.map(product => {
        const productImage = product.images && product.images.length > 0 ? product.images[0].src : '';
        return (
          <div key={product.id} className="product">
            <h1 className="product-title">{product.title}</h1>
            {productImage && <img className="product-image" src={productImage} alt={product.title} />}
            {/* <div className="product-description" dangerouslySetInnerHTML={{ __html: product.body_html }} /> */}
          </div>
        );
      })}
    </div>
  );
};

export default BestsellerProduct;
