import '../style/Header.css'; // Import CSS for styling
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth'; // Import auth methods
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore methods
import { auth, fireDB } from '../firebase'; // Ensure firebase is properly imported

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profileImage, setProfileImage] = useState('profile.jpg'); // Default profile image
  const [userName, setUserName] = useState('');
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!profileMenuOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDoc = await getDoc(doc(fireDB, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setProfileImage(userData.profileImageUrl || 'profile.jpg');
            setUserName(userData.name);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log('User signed out');
        setProfileMenuOpen(false); // Close the profile menu after sign out
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  // Check if the current route is login or signup
  const isAuthPage = location.pathname === '/' || location.pathname === '/signup';

  return (
    <header>
      <div className="logo">
        <Link to="/dashboard"><img src='UNITEE ARTIST.PNG' alt='Logo' /></Link>
      </div>
      {!isAuthPage && (
        <>
          <nav className={menuOpen ? 'active' : ''}>
            <ul>
              {user ? (
                <>
                  <li><a href="/dashboard"><span className='hi1'>Dashboard</span></a></li>
                  {/* <li><a href="/orders">Order</a></li> */}
                  <li><a href="/designs"><span className='hi1'>Design</span></a></li>
                  <li><a href="/collab"><span className='hi1'>Collab</span></a></li>
                  <li>
                    <div className="profile-icon desktop" onClick={toggleProfileMenu}>
                      <img src={profileImage} alt="Profile" />
                      {profileMenuOpen && (
                        <div className="profile-dropdown">
                          {/* <Link to={`/${userName}`}>Profile</Link> */}
                          <Link to="/editprofile"><span className='hi1'>Edit Profile</span></Link>
                          <button onClick={handleSignOut}><Link to="/"><span className='hi1'>Logout</span></Link></button>
                        </div>
                      )}
                    </div>
                  </li>
                </>
              ) : (
                <>
                  <li><Link to="/signup"><button className='li-sign'>Signup</button></Link></li>
                  <li><Link to="/"><button className='li-log'>login</button></Link></li>
                </>
              )}
            </ul>
          </nav>
          <div className={`menu-icon ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </>
      )}
      {user && (
        <div className="profile-icon mobile" onClick={toggleProfileMenu}>
          <img src={profileImage} alt="Profile" />
          {profileMenuOpen && (
            <div className="profile-dropdown">
              {/* <Link to={`/${userName}`}>Profile</Link> */}
              <Link to="/editprofile">Edit Profile</Link>
              <button onClick={handleSignOut}><Link to="/">Logout</Link></button>
            </div>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
