
import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { auth, fireDB } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import '../style/collab.css'
import Header from '../components/Header';


function Collab() {
  return (
    <div>
      <Header/>
    <div className="c-container">
       <Link to="/collab/behind-the-tee" className="c-image-link" target='_blank' rel="noopener" >
      <div className="c-image-container">
        <img src={'btt.jpeg'} alt="Behind the Tee" className="image" />
        <div className="c-text">Behind the Tee</div>
      </div>
      </Link>
      <Link to="/comingsoon" className="c-image-link" target='_blank' rel="noopener">
      <div className="c-image-container">
        <img src={'collab-design.jpeg'} alt="Design" className="image" />
        <div className="c-text">Design</div>
      </div>
      </Link>
      
    </div>
    </div>
  );
}

export default Collab;
