import React, { useState, useEffect } from 'react';
import { auth, fireDB } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Line, Pie, Bar } from 'react-chartjs-2';
import { CategoryScale, Chart as ChartJS } from 'chart.js/auto';
import Header from '../components/Header';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../style/dashboard.css';
import { Map, Marker, Overlay } from 'pigeon-maps';
import { Link } from 'react-router-dom';
import API_URL from '../config';

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [period, setPeriod] = useState('today');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [commission, setCommission] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [customerLocations, setCustomerLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  // const baseUrl = process.env.API_URL;
  const FIXED_COST_CHARGES = 100; // Fixed shipping cost
  const TAX_4_PERCENT = 0.04; // 4% tax
  const COMMISSION_RATE = 0.20; // 20% commission rate

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);

        try {
          const userDocRef = doc(fireDB, 'users', currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            setUserName(userData.name);
            setUserStatus(userData.status);

            if (userData.status === 'approved') {
              const shopifyOrders = await fetchShopifyOrders(userData.name);
              setOrders(shopifyOrders);
              calculateCommission(shopifyOrders, period, startDate, endDate);
              setTotalOrders(shopifyOrders.length);
              extractCustomerLocations(shopifyOrders);
            }
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user document:", error);
        }
      } else {
        setUser(null);
        setOrders([]);
        setTotalOrders(0);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (user && userStatus === 'approved') {
      calculateCommission(orders, period, startDate, endDate);
    }
  }, [period, startDate, endDate, orders]);

  const fetchShopifyOrders = async (userName) => {
    try {
      const response = await axios.get(`${API_URL}/api/orders`, {
        params: { user: userName }
      });

      return response.data;
    } catch (error) {
      console.error('Error fetching Shopify orders:', error.response ? error.response.data : error.message);
      return [];
    }
  };

  const filterOrdersByPeriod = (orders, period, startDate, endDate) => {
    const now = new Date();
    let filteredOrders = [];

    switch (period) {
      case 'today':
        filteredOrders = orders.filter(order => {
          const orderDate = new Date(order.created_at);
          return orderDate.getDate() === now.getDate() &&
                 orderDate.getMonth() === now.getMonth() &&
                 orderDate.getFullYear() === now.getFullYear();
        });
        break;
      case 'week':
        filteredOrders = orders.filter(order => (now - new Date(order.created_at)) / (1000 * 60 * 60 * 24) <= 7);
        break;
      case 'month':
        filteredOrders = orders.filter(order => (now - new Date(order.created_at)) / (1000 * 60 * 60 * 24) <= 30);
        break;
      case 'year':
        filteredOrders = orders.filter(order => (now - new Date(order.created_at)) / (1000 * 60 * 60 * 24) <= 365);
        break;
      case 'date':
        filteredOrders = orders.filter(order => new Date(order.created_at) >= startDate && new Date(order.created_at) <= endDate);
        break;
      default:
        filteredOrders = orders;
    }

    return filteredOrders;
  };

  // const calculateCommission = (orders, period, startDate, endDate) => {
  //   const filteredOrders = filterOrdersByPeriod(orders, period, startDate, endDate);

  //   const totalCommission = filteredOrders.reduce((acc, order) => {
  //     const totalPrice = parseFloat(order.total_price);
  //     // Calculate 12% included tax
  //     const tax12 = totalPrice - (totalPrice / (1 + 0.12));

  //     // Calculate 4% tax of total price
  //     const tax4 = totalPrice * TAX_4_PERCENT;

  //     // Subtract 12% tax, 4% tax, and fixed cost ($100)
  //     const priceAfterDeductions = totalPrice - tax12 - tax4 - FIXED_COST_CHARGES;

  //     // Calculate 20% commission
  //     const commission = priceAfterDeductions * COMMISSION_RATE;

  //     return acc + commission;
  //   }, 0);

  //   setCommission(totalCommission.toFixed(2));
  //   setTotalOrders(filteredOrders.length);
  // };

  const calculateCommission = (orders, period, startDate, endDate) => {
    const filteredOrders = filterOrdersByPeriod(orders, period, startDate, endDate);
  
    const totalCommission = filteredOrders.reduce((acc, order) => {
      // Iterate over each item in the order
      return acc + order.line_items.reduce((itemAcc, item) => {
        const totalPrice = parseFloat(item.price); // Use individual item price
  
        // Calculate 12% included tax
        const tax12 = totalPrice - (totalPrice / (1 + 0.12));
  
        // Calculate 4% tax of total price
        const tax4 = totalPrice * TAX_4_PERCENT;
  
        // Subtract 12% tax, 4% tax, and fixed cost ($100) from item price
        const priceAfterDeductions = totalPrice - tax12 - tax4 - FIXED_COST_CHARGES;
  
        // Calculate 20% commission
        const commission = priceAfterDeductions * COMMISSION_RATE;
  
        return itemAcc + commission;
      }, 0); // Initial value for itemAcc
    }, 0); // Initial value for acc
  
    setCommission(totalCommission.toFixed(2));
    setTotalOrders(filteredOrders.length);
  };

  const extractCustomerLocations = (orders) => {
    const locations = orders.map(order => ({
      lat: order.shipping_address.latitude,
      lng: order.shipping_address.longitude,
      address: order.shipping_address.city
    }));
    setCustomerLocations(locations);
  };

  const getDataForChart = (orders) => {
    const filteredOrders = filterOrdersByPeriod(orders, period, startDate, endDate);

    const labels = filteredOrders.map(order => new Date(order.created_at).toLocaleDateString());
    const data = filteredOrders.map(order => order.total_price);

    return {
      labels,
      datasets: [
        {
          label: 'Total Order Value',
          data,
          fill: false,
          borderColor: 'rgb(128, 0, 128)', // Purple
          tension: 0.1
        }
      ]
    };
  };

  const getDataForPieChart = (orders) => {
    const filteredOrders = filterOrdersByPeriod(orders, period, startDate, endDate);

    const productCount = {};

    filteredOrders.forEach(order => {
      order.line_items.forEach(item => {
        const productName = item.title;

        if (productCount[productName]) {
          productCount[productName] += 1;
        } else {
          productCount[productName] = 1;
        }
      });
    });

    const labels = Object.keys(productCount);
    const data = Object.values(productCount);

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor: [
            '#FFCE56', // Yellow
            '#FF6384', // Red
            '#36A2EB', // Blue
            '#4BC0C0', // Green
            '#9966FF', // Purple
            '#FF9F40'  // Orange
          ]
        }
      ]
    };
  };

  const getDataForBarChart = (orders) => {
    const filteredOrders = filterOrdersByPeriod(orders, period, startDate, endDate);

    const productCommission = {};

    filteredOrders.forEach(order => {
      order.line_items.forEach(item => {
        const productName = item.title;
        const totalPrice = parseFloat(item.price);

        // Calculate 12% included tax
        const tax12 = totalPrice - (totalPrice / (1 + 0.12));

        // Calculate 4% tax of total price
        const tax4 = totalPrice * TAX_4_PERCENT;

        // Subtract 12% tax, 4% tax, and fixed cost ($100)
        const priceAfterDeductions = totalPrice - tax12 - tax4 - FIXED_COST_CHARGES;

        // Calculate 20% commission
        const commission = priceAfterDeductions * COMMISSION_RATE;

        if (productCommission[productName]) {
          productCommission[productName] += commission;
        } else {
          productCommission[productName] = commission;
        }
      });
    });

    const labels = Object.keys(productCommission);
    const data = Object.values(productCommission).map(value => value.toFixed(2));

    return {
      labels,
      datasets: [
        {
          label: 'Product Commission Breakdown',
          data,
          backgroundColor: [
            'rgba(128, 0, 128, 0.6)', // Purple
            'rgba(255, 206, 86, 0.6)', // Yellow
            'rgba(255, 99, 132, 0.6)', // Red
            'rgba(54, 162, 235, 0.6)', // Blue
            'rgba(75, 192, 192, 0.6)', // Green
            'rgba(153, 102, 255, 0.6)' // Purple
          ],
          borderColor: [
            'rgba(128, 0, 128, 1)', // Purple
            'rgba(255, 206, 86, 1)', // Yellow
            'rgba(255, 99, 132, 1)', // Red
            'rgba(54, 162, 235, 1)', // Blue
            'rgba(75, 192, 192, 1)', // Green
            'rgba(153, 102, 255, 1)' // Purple
          ],
          borderWidth: 1
        }
      ]
    };
  };

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <div>
      <Header />
      <div className='dashboard-container'>
        <h2>Dashboard</h2>
        {user ? (
          userStatus === 'approved' ? (
            <>
              <div className='greeting-container'>
                {/* <h3>Hey {userName}, here is your order report:</h3> */}
              </div>
              <div className='filter-container'>
                <label htmlFor="period"><span className='d1'>Select Period:</span></label>
                <select id="period" value={period} onChange={handlePeriodChange}>
                  <option value="today">Today</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                  <option value="year">Year</option>
                  <option value="date">Custom Date</option>
                </select>
                {period === 'date' && (
                  <div className='date-picker-container'>
                    <label htmlFor="start-date">Start Date:</label>
                    <DatePicker
                      id="start-date"
                      selected={startDate}
                      onChange={handleStartDateChange}
                      dateFormat="yyyy/MM/dd"
                    />
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                      id="end-date"
                      selected={endDate}
                      onChange={handleEndDateChange}
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                )}
              </div>
              <div className='card-container'>
                <div className='card'>
                <Link to='/products'>
                  <span className='card-title'>Add Design</span>
                  <span className='card-value'><FontAwesomeIcon icon={faPlus} /></span>
                  </Link>
                </div>
                <div className='card'>
                  <span className='card-title'>Total Orders</span>
                  <span className='card-value'>{totalOrders}</span>
                </div>
                <div className='card'>
                  <span className='card-title'>Total Commission</span>
                  <span className='card-value'>₹{commission}</span>
                </div>
              </div>
              <div className='chart'>
                  <Line data={getDataForChart(orders)} />
              </div>

              <div className='chart-container'>
              
                <div className='chart'>
                  <Pie data={getDataForPieChart(orders)} />
                </div>
                <div className='chart'>
                  <Bar data={getDataForBarChart(orders)} />
                </div>
                <div className='chart'>
                  <h4 align><span className='d1'>Places you reached</span></h4>
                  <Map height={400} defaultCenter={[20.5937, 78.9629]} defaultZoom={4}>
                    {customerLocations.map((location, index) => (
                      <Marker key={index} width={50} anchor={[location.lat, location.lng]} onClick={() => setSelectedLocation(location)} />
                    ))}
                    {selectedLocation && (
                      <Overlay anchor={[selectedLocation.lat, selectedLocation.lng]} offset={[0, 0]}>
                        <div className="popup">{selectedLocation.address}</div>
                      </Overlay>
                    )}
                  </Map>
                </div>
              </div>
            </>
          ) : (
            <p>Your application is under process. Please wait for approval.</p>
          )
        ) : (
          <p>Please log in to view your dashboard.</p>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
