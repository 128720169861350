import React, { useState, useEffect } from 'react';
import { fireDB } from '../firebase';
import { collection, getDocs, updateDoc, doc, getDoc, deleteDoc } from 'firebase/firestore';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import AdminHeader from '../components/AdminHeader';
import API_URL from '../config';

Modal.setAppElement('#root');

const AdminDesignsPage = () => {
  const [designs, setDesigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const designsCollection = collection(fireDB, 'designs');
        const designsSnapshot = await getDocs(designsCollection);
        const designsData = designsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setDesigns(designsData);
      } catch (error) {
        console.error('Error fetching designs:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDesigns();
  }, []);

  const approveDesign = async (designId, userName) => {
    try {
      const designDoc = await getDoc(doc(fireDB, 'designs', designId));
      const designData = designDoc.data();
  
      if (!designData) {
        console.log('No design data found');
        return;
      }
  
      console.log('Design Data:', designData);
  
      const skus = designData.skus;
  
      if (!skus || skus.length === 0) {
        console.log('No SKUs found in design data');
        return;
      }
  
      console.log('Fetched SKUs:', skus);
  
      // Post front and back mockups to Shopify as a draft product
      await axios.post(`${API_URL}/api/uploadProduct?user=${userName}`, {
        title: designData.designName,
        body_html: designData.description,
        front_image: designData.frontMockupURL,
        back_image: designData.backMockupURL,
        skus // Include the SKU list
      });
  
      await updateDoc(doc(fireDB, 'designs', designId), {
        status: 'approved'
      });
  
      console.log('Design approved successfully');
      setDesigns(prevDesigns =>
        prevDesigns.map(design => (design.id === designId ? { ...design, status: 'approved' } : design))
      );
    } catch (error) {
      console.error('Error approving design:', error.message);
    }
  };
  
  

  const disapproveDesign = async (designId) => {
    try {
      await updateDoc(doc(fireDB, 'designs', designId), {
        status: 'disapproved'
      });
      console.log('Design disapproved successfully');
      setDesigns(prevDesigns =>
        prevDesigns.map(design => (design.id === designId ? { ...design, status: 'disapproved' } : design))
      );
    } catch (error) {
      console.error('Error disapproving design:', error.message);
    }
  };

  const deleteDesign = async (designId) => {
    try {
      await deleteDoc(doc(fireDB, 'designs', designId));
      console.log('Design deleted successfully');
      setDesigns(prevDesigns => prevDesigns.filter(design => design.id !== designId));
    } catch (error) {
      console.error('Error deleting design:', error.message);
    }
  };

  const openModal = (design) => {
    setSelectedDesign(design);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedDesign(null);
    setModalIsOpen(false);
  };

  const totalPages = Math.ceil(designs.length / itemsPerPage);
  const currentDesigns = designs.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div>
      <div className='admin-container'>
        <AdminHeader/>
        <h2>Design Approvals</h2>
        {loading ? (
          <p>Loading designs...</p>
        ) : (
          <table className='designs-table'>
            <thead>
              <tr>
                <th>Design Name</th>
                <th>Front Design Image</th>
                <th>Front Mockup</th>
                <th>Back Design Image</th>
                <th>Back Mockup</th>
                <th>Signature</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentDesigns.map(design => (
                <tr key={design.id}>
                  <td>{design.designName}</td>
                  <td>
                    <img
                      src={design.frontDesignURL}
                      alt={`${design.designName} Front Design Image`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={design.frontMockupURL}
                      alt={`${design.designName} Front Mockup`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={design.backDesignURL}
                      alt={`${design.designName} Back Design Image`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={design.backMockupURL}
                      alt={`${design.designName} Back Mockup`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>
                    <img
                      src={design.signatureURL}
                      alt={`${design.designName} Signature`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                    />
                  </td>
                  <td>{design.status === 'approved' ? 'Approved' : 'Pending'}</td>
                  {/* <td>{new Date(design.createdAt.seconds * 1000).toLocaleDateString()}</td> */}
                  <td>
                    {design.status !== 'approved' && (
                      <>
                        <button onClick={() => approveDesign(design.id, design.userName)}>Approve</button>
                        <button onClick={() => disapproveDesign(design.id)}>Disapprove</button>
                      </>
                    )}
                    <FontAwesomeIcon icon={faTrash} onClick={() => openModal(design)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div className='pagination'>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</button>
        </div>
      </div>
      {selectedDesign && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Delete Design Modal"
        >
          <h2>Are you sure you want to delete this design?</h2>
          <img
            src={selectedDesign.frontDesignURL}
            alt={`${selectedDesign.designName} Front Design Image`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
          <img
            src={selectedDesign.frontMockupURL}
            alt={`${selectedDesign.designName} Front Mockup`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
          <img
            src={selectedDesign.backDesignURL}
            alt={`${selectedDesign.designName} Back Design Image`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
          <img
            src={selectedDesign.backMockupURL}
            alt={`${selectedDesign.designName} Back Mockup`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
          <img
            src={selectedDesign.signatureURL}
            alt={`${selectedDesign.designName} Signature`}
            style={{ width: '150px', height: '150px', objectFit: 'cover' }}
          />
          <button onClick={() => deleteDesign(selectedDesign.id)}>Delete</button>
          <button onClick={closeModal}>Cancel</button>
        </Modal>
      )}
    </div>
  );
};

export default AdminDesignsPage;
