// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
import {getFirestore} from "firebase/firestore";
import {getStorage, ref, uploadBytes} from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAuFpvtRcrqUKmfl9UgZ3jbIq3c8KTK88Y",
  authDomain: "artist-panel.firebaseapp.com",
  projectId: "artist-panel",
  storageBucket: "artist-panel.appspot.com",
  messagingSenderId: "280133476583",
  appId: "1:280133476583:web:183de06de0cd28678a0242"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireDB= getFirestore(app);
const auth = getAuth(app);
const fireStorage= getStorage(app);

export {fireDB, auth, fireStorage, ref};
