import { useState } from 'react';
import '../style/signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Header from '../components/Header';

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        navigate("/dashboard");
      })
      .catch((error) => {
        setError(true);
        console.error('Login Error:', error.message);
      });
  };

  return (
    <div>
      <Header />
      <div className='signup-container'>
        <form className='signupform' onSubmit={handleSubmit}>
          <h2>Login</h2>
          <label htmlFor='email'>
            Email:
            <input
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor='password'>
            Password:
            <input
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <button type='submit'>Login</button>
          <p>
            Don't have an Account? <Link to='/signup'>Signup</Link>
          </p>
          {error && <span>Incorrect email or password.</span>}
          <p>
          <Link to='/login/forgetPassword'>
            Forgot Password?
          </Link>
        </p>
        </form>
      
      </div>
    </div>
  );
};

export default Login;
